.preview-container {
    width: 150px;
    height: 95px;
    position: relative;
    padding: 5px;

    .cross-button {
        cursor: pointer;
        width: 15px;
        height: 15px;
        background-color: white;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 50px;
    }

    .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #cecece;
    }
}

.rounded-img-container {
    border-radius: 6px !important;
}
