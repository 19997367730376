// Search box styling

.trailer-container {
    .header-button {
        width: 240px;
        text-align: center;
        font-size: 1rem;
        background: transparent linear-gradient(0deg, #2c3e50 0%, #2980b9 100%)
            0% 0% no-repeat padding-box;
        color: #f0f5f8 !important;
        border-radius: 10px !important;
    }
}

.custom-input-search {
    height: 50px;
    border: none !important;
    background: #eeeeee;
    border-radius: 10px !important;
    width: 250px;
    font-size: 16px;
    padding: 0.75px 15px;
}
@media (max-width: 1300px) {
    .custom-input-search {
        width: 200px;
    }
}

@media (max-width: 1024px) {
    .custom-input-search-height {
        height: 100%;
    }
}

.custom-input-search-icon {
    font-size: 30px;
    color: #a2a2a2;
    position: absolute;
    right: 13px;
    top: 10px;
    bottom: 0;
    line-height: none !important;
}

.search-input-container-class {
    white-space: nowrap;
    background: #eeeeee;
    border-radius: 10px !important;
    padding: 0px 5px;
}

@media (max-width: 992px) {
    .search-input-container-class > input {
        height: 50px !important;
        width: 200px !important;
    }

    .trailer-container {
        .header-button {
            width: 230px !important;
        }
    }
}

@media (max-width: 940px) {
    .search-input-container-class > input {
        height: 40px;
        width: 95% !important;
    }
}
@media (max-width: 940px) {
    .search-input-container-class > input {
        height: 40px;
        width: 95% !important;
    }
}
@media (max-width: 992px) {
    .search-input-container-class > input {
        height: 40px !important;
        width: 190px !important;
    }
}
@media (max-width: 720px) {
    .search-input-container-class > input {
        width: 88% !important;
    }
    .trailer-container {
        .header-button {
            width: 100% !important;
        }
    }
}

@media (max-width: 700px) {
    > .search-input-container-class > input {
        width: 93%;
    }
    .trailer-container {
        .header-button {
            width: 100% !important;
        }
        .headerButtonResponsive {
            margin-top: 10px;
        }
    }
}

// Search box styling end
