.fm-main-heading {
    color: #000;
}
.black-color {
    color: #000000 !important;
}
.fm-ftwg-600 {
    font-weight: 600 !important;
}
.fm-br-10 {
    border-radius: 10px !important;
}
.fm-bg-input {
    background: #eeeeee;
}

.fm-main-heading-btn {
    padding: 8px 35px !important;
    font-size: 16px !important;
}

/****** FM TOGGLE BUTTON ******/
.ad-itemsorders-toggle-con .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.ad-itemsorders-toggle-con .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ad-itemsorders-toggle-con .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dedede;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.ad-itemsorders-toggle-con .slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 0px;
    bottom: 0px;
    background-color: #297eb6;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

// .ad-itemsorders-toggle-con input:focus +  .slider {
//   box-shadow: 0 0 1px #2196F3;
// }

.ad-itemsorders-toggle-con input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.ad-itemsorders-toggle-con .slider.round {
    border-radius: 34px;
}

.ad-itemsorders-toggle-con .slider.round:before {
    border-radius: 50%;
}

/****** FM TOGGLE BUTTON ENDED ******/
.ad-itemsorders-toggle-con > span {
    font-size: 16px;
    color: #c4c4c4;
    font-weight: 500;
}
.ad-itemsorders-toggle-con > .active {
    color: #297eb6;
}
.fm-table-checkboxes {
    width: 15px !important;
    height: 15px !important;
    background: #ffffff;
    border: 1px solid #cecece !important;
    border-radius: 4px !important;
}

.fm-table-selectall {
    vertical-align: bottom;
}

.fm-table-con {
    box-shadow: 0px 12px 18px #0000000d;
    border-radius: 0px 0px 4px 4px;
}
.fm-bg-table-header {
    background: #f8f9fa;
}
.fm-bg-table-footer {
    background: #ffffff;
}
.fm-table-con table thead th {
    color: #000000 !important;
}
.fm-table-con table tbody td {
    color: #495057 !important;
}
.place-order-data-table {
    box-shadow: 0px 12px 18px #0000000d;
    border-radius: 0px 0px 4px 4px;
}
.place-order-data-table table tbody td {
    color: #495057;
}

.place-order-data-table table tbody tr:last-child td {
    padding-bottom: 100px;
}
.ad-status-search-con {
    width: 250px;
}
.status-label {
    font-size: 13px;
    line-height: 20px;
    color: #495057;
}

.fm-status-header-search__control {
    height: 50px;
    min-height: 50px !important;
    background-color: #eeeeee;
    font-size: 16px;
}

.fm-status-header-search__placeholder,
.place-order-table-search__placeholder {
    color: #000000 !important;
}
.place-order-table-search__indicator > svg {
    height: 15px;
    width: 15px;
}
.place-order-table-search__placeholder {
    opacity: 0.6;
}
.fm-status-header-search__indicator,
.place-order-table-search__indicator {
    color: #74788d !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.fm-status-header-search__indicator,
.place-order-table-search__indicator,
.place-order-table-head-search__indicator {
    color: #000000 !important;
}
.fm-status-header-search__indicators .place-order-table-search__indicators,
.place-order-table-head-search__indicator {
    min-width: inherit;
}
.fm-status-header-search__indicator-separator,
.place-order-table-search__indicator-separator,
.place-order-table-head-search__indicator-separator {
    display: none;
}
.fm-table-input {
    height: 50px;
    border: none !important;
    background: #eeeeee;
    border-radius: 10px !important;
    width: 260px;
    font-size: 16px;
    padding: 0.75px 15px;
}
.fm-search-input::placeholder {
    color: #a2a2a2;
}
.fm-table-search-icon {
    font-size: 30px;
    color: #a2a2a2;
    vertical-align: middle;
}
.fm-table-search-con {
    white-space: nowrap;
    background: #eeeeee;
    border-radius: 10px !important;
    padding: 0px 5px;
    width: 300px;
}
.fm-bg-light-green {
    background-color: #34c38f80;
    color: #004a2f;
}
.fm-bg-light-yellow {
    background-color: #f1b44c80;
    color: #754f0d;
}
.fm-bg-light-gray {
    background-color: #a3a3a3;
    color: #3b3b3b;
}
.fm-bg-light-red {
    background-color: #f46a6a80;
    color: #950000;
}
.place-order-status,
.fm-om-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 15px;
}
.fm-om-status {
    border-radius: 20px;
    width: 110px;
}
.place-order-status {
    width: 160px;
    height: 30px;
    border-radius: 93px;
}
.ad-itemsorders-toggle-con {
    display: inline-block;
    width: 33.33%;
}
.ad-itemsorders-toggle-right-con {
    display: inline-block;
    width: 66.66%;
}
.fm-req-popup-heading {
    font-size: 42px;
    color: #000;
}
.fm-place-order-table table thead th {
    background-color: #297eb6;
    color: #fff !important;
    font-weight: 500 !important;
}

.place-order-table-search-con {
    width: 150px;
}
.place-order-table-search__control {
    height: 30px;
    min-height: 30px !important;
    background-color: #eeeeee;
    font-size: 12px;
}
.place-order-input {
    width: 150px !important;
    height: 30px !important;
    background: #eeeeee !important;
    border-radius: 2px !important;
    opacity: 1 !important;
    border: none !important;
}
.table-column-bold {
    color: #000 !important;
    font-weight: 600 !important;
}
.fm-bg-light-blue {
    background-color: #d4e5f0;
    color: #297eb6;
}
.place-order-btn {
    font-size: 18px !important;
    width: 369px;
    height: 50px;
    border-radius: 5px;
}
.place-order-top-date {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.place-order-top-date > span {
    font-weight: 500;
}

.place-order-head-input {
    height: 50px !important;
    background: #eeeeee !important;
    border-radius: 10px !important;
    border: none !important;
}

.place-order-table-head-search {
    width: 100%;
}
.place-order-table-head-search__control {
    height: 50px;
    min-height: 50px !important;
    font-size: 16px;
}
.place-order-img-main-con {
    margin-top: 2rem !important;
}
.create-order-modal-ip {
    min-width: 150px;
}
.input-append-dollar-con,
.input-append-dollar-con > input,
.input-append-dollar-con > input:active,
.input-append-dollar-con > input:focus,
.input-append-dollar-oq,
.input-append-dollar-oq:active,
.input-append-dollar-oq:focus {
    background: #eeeeee !important;
    color: #000000 !important;
    font-size: 13px !important;
}
.input-append-dollar-con > input::placeholder,
.input-append-dollar-oq::placeholder {
    color: #000000 !important;
}
.placeorder-unit-price {
    padding-top: 0.47rem;
    padding-bottom: 0.47rem;
}
.placeorder-unit-price > input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: normal !important;
}
.byorder-toggle {
    width: 33.33%;
    vertical-align: middle;
}

.byorder-toggle-con {
    width: 66.66%;
}
.po-detail-table table tbody tr:last-child td {
    padding-bottom: 0.75em !important;
}
