.trailer-container {
    .header-button {
        width: 240px;
        text-align: center;
        font-size: 1rem;
        background: transparent linear-gradient(0deg, #2c3e50 0%, #2980b9 100%)
            0% 0% no-repeat padding-box;
        color: #f0f5f8 !important;
        border-radius: 10px !important;
    }
}
